import { useQuery } from 'react-query';
import { getUserInfo } from '../../services/services';
import { UserInfo } from '../../services/types';

const fetchUserInfo = async (): Promise<UserInfo | undefined> => {
  try {
    const { data } = await getUserInfo();
    return data;
  } catch (error) {
    return undefined;
  }
};

export function useUserInfo(isLogin: string) {
  return useQuery(['user-info', isLogin], () => fetchUserInfo(), {
    enabled: isLogin === 'yes',
  });
}
