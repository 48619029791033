import Box, { BoxProps } from '@material-ui/core/Box';
import { ReactComponent as LogoSvgDark } from '../assets/thumbnaillogo-dark.svg';
import { ReactComponent as LogoSvgLight } from '../assets/thumbnaillogo-light.svg';
import { useTheme } from '@material-ui/core/styles';

type ThumbnailLogoProps = {
  colored?: boolean;
  size?: number;
} & BoxProps;

const ThumbnailLogo = ({ colored = false, size = 40, ...boxProps }: ThumbnailLogoProps) => {
  const theme = useTheme();

  return (
    <Box {...boxProps}>
      {theme.palette.mode === 'dark' ? <LogoSvgDark height={size} width={size} /> : <LogoSvgLight height={size} width={size} />}
    </Box>
  );
};

export default ThumbnailLogo;
