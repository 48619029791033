/**
 * AUTO_GENERATED Do not change this file directly, use config.ts file instead
 *
 * @version 5
 */

import { AxiosRequestConfig } from 'axios';
import { SwaggerResponse } from './config';
import { Http } from './httpRequest';
import {
  CreateUser,
  UpdateUser,
  UpdateUserSetting,
  UserInfo,
  LoginUser,
  LogoutUser,
  ForgotPwdParams,
  ForgotPwdSubmitParams,
  ResetPwdParams,
  TemplatesDelete,
  Orders,
  ValidateOrderParam,
  RequestBodyTemplates,
  RequestBodyEvents,
} from './types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const __DEV__ = process.env.NODE_ENV !== 'production';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function overrideConfig(config?: AxiosRequestConfig, configOverride?: AxiosRequestConfig): AxiosRequestConfig {
  return {
    ...config,
    ...configOverride,
    headers: {
      ...config?.headers,
      ...configOverride?.headers,
    },
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function template(path: string, obj: { [x: string]: any } = {}) {
  Object.keys(obj).forEach(key => {
    const re = new RegExp(`{${key}}`, 'i');
    path = path.replace(re, obj[key]);
  });

  return path;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function objToForm(requestBody: object) {
  const formData = new FormData();

  Object.entries(requestBody).forEach(([key, value]) => {
    value && formData.append(key, value);
  });

  return formData;
}

/**
 *
 * Delete Event By Id
 */
export const deleteEventsId = (
  /**
   *
   * events Id
   */
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(template(deleteEventsId.key, { id }), undefined, undefined, undefined, overrideConfig(_CONSTANT0, configOverride));
};

/** Key is end point string without base url */
deleteEventsId.key = '/events/{id}';

/**
 *
 * Delete Templates By Id
 */
export const deleteTemplates = (requestBody: TemplatesDelete, configOverride?: AxiosRequestConfig): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(deleteTemplates.key, undefined, requestBody, undefined, overrideConfig(_CONSTANT0, configOverride));
};

/** Key is end point string without base url */
deleteTemplates.key = '/templates';

/**
 *
 * Delete User By Id
 */
export const deleteUsersId = (
  /**
   *
   * user Id
   */
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(template(deleteUsersId.key, { id }), undefined, undefined, undefined, overrideConfig(_CONSTANT0, configOverride));
};

/** Key is end point string without base url */
deleteUsersId.key = '/users/{id}';

/**
 *
 * Find All Events
 */
export const getEvents = (configOverride?: AxiosRequestConfig): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(getEvents.key, undefined, undefined, undefined, overrideConfig(_CONSTANT0, configOverride));
};

/** Key is end point string without base url */
getEvents.key = '/events';

/**
 *
 * Find Today events for extension
 */
export const getEventsExtension = (configOverride?: AxiosRequestConfig): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(getEventsExtension.key, undefined, undefined, undefined, overrideConfig(_CONSTANT0, configOverride));
};

/** Key is end point string without base url */
getEventsExtension.key = '/events/extension';

/**
 *
 * Find All Orders
 */
export const getOrders = (configOverride?: AxiosRequestConfig): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(getOrders.key, undefined, undefined, undefined, overrideConfig(_CONSTANT0, configOverride));
};

/** Key is end point string without base url */
getOrders.key = '/orders';

/**
 *
 * Find All Templates
 */
export const getTemplates = (configOverride?: AxiosRequestConfig): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(getTemplates.key, undefined, undefined, undefined, overrideConfig(_CONSTANT0, configOverride));
};

/** Key is end point string without base url */
getTemplates.key = '/templates';

/**
 *
 * classifiedStatistics
 */
export const getTemplatesClassifiedStatistics = (configOverride?: AxiosRequestConfig): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(getTemplatesClassifiedStatistics.key, undefined, undefined, undefined, overrideConfig(_CONSTANT0, configOverride));
};

/** Key is end point string without base url */
getTemplatesClassifiedStatistics.key = '/templates/classifiedStatistics';

/**
 *
 * fetch ali-oss credential
 */
export const getTemplatesCredential = (configOverride?: AxiosRequestConfig): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(getTemplatesCredential.key, undefined, undefined, undefined, overrideConfig(_CONSTANT0, configOverride));
};

/** Key is end point string without base url */
getTemplatesCredential.key = '/templates/credential';

/**
 *
 * Find All Templates from extension
 */
export const getTemplatesExtension = (configOverride?: AxiosRequestConfig): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(getTemplatesExtension.key, undefined, undefined, undefined, overrideConfig(_CONSTANT0, configOverride));
};

/** Key is end point string without base url */
getTemplatesExtension.key = '/templates/extension';

/**
 *
 * Find Template By Id
 */
export const getTemplatesId = (
  /**
   *
   * Template Id
   */
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(template(getTemplatesId.key, { id }), undefined, undefined, undefined, overrideConfig(_CONSTANT0, configOverride));
};

/** Key is end point string without base url */
getTemplatesId.key = '/templates/{id}';

/**
 *
 * Find All teamTags from teamMembers
 */
export const getTemplatesTeamTags = (configOverride?: AxiosRequestConfig): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(getTemplatesTeamTags.key, undefined, undefined, undefined, overrideConfig(_CONSTANT0, configOverride));
};

/** Key is end point string without base url */
getTemplatesTeamTags.key = '/templates/teamTags';

/**
 *
 * fetch userInfo
 */
export const getUserInfo = (configOverride?: AxiosRequestConfig): Promise<SwaggerResponse<UserInfo>> => {
  return Http.getRequest(getUserInfo.key, undefined, undefined, undefined, overrideConfig(_CONSTANT1, configOverride));
};

/** Key is end point string without base url */
getUserInfo.key = '/userInfo';

/**
 *
 * Find All Users
 */
export const getUsers = (configOverride?: AxiosRequestConfig): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(getUsers.key, undefined, undefined, undefined, overrideConfig(_CONSTANT0, configOverride));
};

/** Key is end point string without base url */
getUsers.key = '/users';

/**
 *
 * Find User By Id
 */
export const getUsersId = (
  /**
   *
   * User Id
   */
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(template(getUsersId.key, { id }), undefined, undefined, undefined, overrideConfig(_CONSTANT0, configOverride));
};

/** Key is end point string without base url */
getUsersId.key = '/users/{id}';

/**
 *
 * get User teamMembers By manager Id
 */
export const getUsersTeamMembers = (configOverride?: AxiosRequestConfig): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(getUsersTeamMembers.key, undefined, undefined, undefined, overrideConfig(_CONSTANT0, configOverride));
};

/** Key is end point string without base url */
getUsersTeamMembers.key = '/users/teamMembers';

/**
 *
 * Add Events
 */
export const postEvents = (requestBody: RequestBodyEvents, configOverride?: AxiosRequestConfig): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(postEvents.key, undefined, requestBody, undefined, overrideConfig(_CONSTANT0, configOverride));
};

/** Key is end point string without base url */
postEvents.key = '/events';

/**
 *
 * forgot pwd step1 - send email vcode
 */
export const postForgotPwd = (requestBody: ForgotPwdParams, configOverride?: AxiosRequestConfig): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(postForgotPwd.key, undefined, requestBody, undefined, overrideConfig(_CONSTANT0, configOverride));
};

/** Key is end point string without base url */
postForgotPwd.key = '/forgotPwd';

/**
 *
 * login User
 */
export const postLogin = (requestBody: LoginUser, configOverride?: AxiosRequestConfig): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(postLogin.key, undefined, requestBody, undefined, overrideConfig(_CONSTANT0, configOverride));
};

/** Key is end point string without base url */
postLogin.key = '/login';

/**
 *
 * logout User
 */
export const postLogout = (requestBody: LogoutUser, configOverride?: AxiosRequestConfig): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(postLogout.key, undefined, requestBody, undefined, overrideConfig(_CONSTANT0, configOverride));
};

/** Key is end point string without base url */
postLogout.key = '/logout';

/**
 *
 * Add Orders
 */
export const postOrders = (requestBody: Orders, configOverride?: AxiosRequestConfig): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(postOrders.key, undefined, requestBody, undefined, overrideConfig(_CONSTANT0, configOverride));
};

/** Key is end point string without base url */
postOrders.key = '/orders';

/**
 *
 * validateOrder Orders
 */
export const postOrdersValidateOrder = (requestBody: ValidateOrderParam, configOverride?: AxiosRequestConfig): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(postOrdersValidateOrder.key, undefined, requestBody, undefined, overrideConfig(_CONSTANT0, configOverride));
};

/** Key is end point string without base url */
postOrdersValidateOrder.key = '/orders/validateOrder';

/**
 *
 * signup User
 */
export const postSignup = (requestBody: CreateUser, configOverride?: AxiosRequestConfig): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(postSignup.key, undefined, requestBody, undefined, overrideConfig(_CONSTANT0, configOverride));
};

/** Key is end point string without base url */
postSignup.key = '/signup';

/**
 *
 * Add Template
 */
export const postTemplates = (requestBody: RequestBodyTemplates, configOverride?: AxiosRequestConfig): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(postTemplates.key, undefined, requestBody, undefined, overrideConfig(_CONSTANT0, configOverride));
};

/** Key is end point string without base url */
postTemplates.key = '/templates';

/**
 *
 * Find Template Content from extension
 */
export const postTemplatesExtensionContent = (requestBody: any, configOverride?: AxiosRequestConfig): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(postTemplatesExtensionContent.key, undefined, requestBody, undefined, overrideConfig(_CONSTANT0, configOverride));
};

/** Key is end point string without base url */
postTemplatesExtensionContent.key = '/templates/extension/content';

/**
 *
 * Add User
 */
export const postUsers = (requestBody: CreateUser, configOverride?: AxiosRequestConfig): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(postUsers.key, undefined, requestBody, undefined, overrideConfig(_CONSTANT0, configOverride));
};

/** Key is end point string without base url */
postUsers.key = '/users';

/**
 *
 * invite user
 */
export const postUsersInviteEmail = (
  /**
   *
   * user email
   */
  email: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(template(postUsersInviteEmail.key, { email }), undefined, undefined, undefined, overrideConfig(_CONSTANT0, configOverride));
};

/** Key is end point string without base url */
postUsersInviteEmail.key = '/users/invite/{email}';

/**
 *
 * events done by id
 */
export const putEventsDoneId = (
  /**
   *
   * events Id
   */
  id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(template(putEventsDoneId.key, { id }), undefined, undefined, undefined, overrideConfig(_CONSTANT0, configOverride));
};

/** Key is end point string without base url */
putEventsDoneId.key = '/events/done/{id}';

/**
 *
 * events manual By Id
 */
export const putEventsId = (
  /**
   *
   * events Id
   */
  id: string,
  requestBody: RequestBodyEvents,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(template(putEventsId.key, { id }), undefined, requestBody, undefined, overrideConfig(_CONSTANT0, configOverride));
};

/** Key is end point string without base url */
putEventsId.key = '/events/{id}';

/**
 *
 * forgot pwd step2 - udpate user pwd
 */
export const putForgotPwdSubmitId = (
  /**
   *
   * user Id
   */
  id: string,
  requestBody: ForgotPwdSubmitParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(template(putForgotPwdSubmitId.key, { id }), undefined, requestBody, undefined, overrideConfig(_CONSTANT0, configOverride));
};

/** Key is end point string without base url */
putForgotPwdSubmitId.key = '/forgotPwdSubmit/{id}';

/**
 *
 * orders manual By Id
 */
export const putOrdersEmailType = (
  /**
   *
   * manual type
   */
  type: string,
  /**
   *
   * manual user email
   */
  email: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putOrdersEmailType.key, { type, email }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putOrdersEmailType.key = '/orders/{email}/{type}';

/**
 *
 * leave team
 */
export const putOrdersLeave = (configOverride?: AxiosRequestConfig): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(putOrdersLeave.key, undefined, undefined, undefined, overrideConfig(_CONSTANT0, configOverride));
};

/** Key is end point string without base url */
putOrdersLeave.key = '/orders/leave';

/**
 *
 * reset user pwd
 */
export const putResetPwdId = (
  /**
   *
   * user Id
   */
  id: string,
  requestBody: ResetPwdParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(template(putResetPwdId.key, { id }), undefined, requestBody, undefined, overrideConfig(_CONSTANT0, configOverride));
};

/** Key is end point string without base url */
putResetPwdId.key = '/resetPwd/{id}';

/**
 *
 * Template User By Id
 */
export const putTemplatesId = (
  /**
   *
   * user Id
   */
  id: string,
  requestBody: RequestBodyTemplates,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(template(putTemplatesId.key, { id }), undefined, requestBody, undefined, overrideConfig(_CONSTANT0, configOverride));
};

/** Key is end point string without base url */
putTemplatesId.key = '/templates/{id}';

/**
 *
 * set User belongTo By Id
 */
export const putUsersAddmemberEmailType = (
  /**
   *
   * user email
   */
  email: string,
  /**
   *
   * manual type
   */
  type: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putUsersAddmemberEmailType.key, { email, type }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putUsersAddmemberEmailType.key = '/users/addmember/{email}/{type}';

/**
 *
 * Update User By Id
 */
export const putUsersId = (
  /**
   *
   * user Id
   */
  id: string,
  requestBody: UpdateUser,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(template(putUsersId.key, { id }), undefined, requestBody, undefined, overrideConfig(_CONSTANT0, configOverride));
};

/** Key is end point string without base url */
putUsersId.key = '/users/{id}';

/**
 *
 * set User setting By Id
 */
export const putUsersSettingId = (
  /**
   *
   * user Id
   */
  id: string,
  requestBody: UpdateUserSetting,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(template(putUsersSettingId.key, { id }), undefined, requestBody, undefined, overrideConfig(_CONSTANT0, configOverride));
};

/** Key is end point string without base url */
putUsersSettingId.key = '/users/setting/{id}';
export const _CONSTANT0 = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};
export const _CONSTANT1 = {
  headers: {
    'Content-Type': 'application/json',
    Accept: '*/*',
  },
};
