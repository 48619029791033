/**
 * AUTO_GENERATED Do not change this file directly, use config.ts file instead
 *
 * @version 5
 */

import { AxiosRequestConfig } from 'axios';
import { UseQueryOptions, useQuery, useMutation, UseMutationOptions, QueryClient, QueryKey } from 'react-query';
import { RequestError, SwaggerResponse } from './config';

import {
  CreateUser,
  ForgotPwdParams,
  ForgotPwdSubmitParams,
  LoginUser,
  LogoutUser,
  Orders,
  RequestBodyEvents,
  RequestBodyTemplates,
  ResetPwdParams,
  TemplatesDelete,
  UpdateUser,
  UpdateUserSetting,
  UserInfo,
  ValidateOrderParam,
} from './types';
import {
  deleteEventsId,
  deleteTemplates,
  deleteUsersId,
  getEvents,
  getEventsExtension,
  getOrders,
  getTemplates,
  getTemplatesClassifiedStatistics,
  getTemplatesCredential,
  getTemplatesExtension,
  getTemplatesId,
  getTemplatesTeamTags,
  getUserInfo,
  getUsers,
  getUsersId,
  getUsersTeamMembers,
  postEvents,
  postForgotPwd,
  postLogin,
  postLogout,
  postOrders,
  postOrdersValidateOrder,
  postSignup,
  postTemplates,
  postTemplatesExtensionContent,
  postUsers,
  postUsersInviteEmail,
  putEventsDoneId,
  putEventsId,
  putForgotPwdSubmitId,
  putOrdersEmailType,
  putOrdersLeave,
  putResetPwdId,
  putTemplatesId,
  putUsersAddmemberEmailType,
  putUsersId,
  putUsersSettingId,
} from './services';

type InternalMutationDefaultParams<TExtra> = { _extraVariables?: TExtra; configOverride?: AxiosRequestConfig };
type InternalUseQueryOptions<TData> = UseQueryOptions<SwaggerResponse<TData>, RequestError | Error>;

type InternalUseMutationOptions<TData, TRequest, TExtra> = UseMutationOptions<
  SwaggerResponse<TData>,
  RequestError | Error,
  TRequest & InternalMutationDefaultParams<TExtra>
>;

type InternalUseMutationOptionsVoid<TData, TExtra> = UseMutationOptions<
  SwaggerResponse<TData>,
  RequestError | Error,
  InternalMutationDefaultParams<TExtra> | void
>;

/**
 *
 * Delete Event By Id
 */
export const useDeleteEventsId = <TExtra>(
  options?: InternalUseMutationOptions<
    any,
    {
      /**
       *
       * events Id
       */
      id: string;
    },
    TExtra
  >,
) => {
  return useMutation(_o => {
    const {
      id,

      configOverride,
    } = _o || {};

    return deleteEventsId(
      id,

      configOverride,
    );
  }, options);
};

/**
 *
 * Delete Templates By Id
 */
export const useDeleteTemplates = <TExtra>(options?: InternalUseMutationOptions<any, { requestBody: TemplatesDelete }, TExtra>) => {
  return useMutation(_o => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return deleteTemplates(
      requestBody,

      configOverride,
    );
  }, options);
};

/**
 *
 * Delete User By Id
 */
export const useDeleteUsersId = <TExtra>(
  options?: InternalUseMutationOptions<
    any,
    {
      /**
       *
       * user Id
       */
      id: string;
    },
    TExtra
  >,
) => {
  return useMutation(_o => {
    const {
      id,

      configOverride,
    } = _o || {};

    return deleteUsersId(
      id,

      configOverride,
    );
  }, options);
};

/**
 *
 * Find All Events
 */
export const useGetEvents = (options?: InternalUseQueryOptions<any>, configOverride?: AxiosRequestConfig) => {
  const { key, fun } = useGetEvents.info(configOverride);
  return useQuery(key, fun, options);
};
useGetEvents.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getEvents.key] as QueryKey,
    fun: () => getEvents(configOverride),
  };
};
useGetEvents.prefetch = (client: QueryClient, options?: InternalUseQueryOptions<any>, configOverride?: AxiosRequestConfig) => {
  const { key, fun } = useGetEvents.info(configOverride);

  return client.getQueryData(key) ? Promise.resolve() : client.prefetchQuery(key, () => fun(), options);
};

/**
 *
 * Find Today events for extension
 */
export const useGetEventsExtension = (options?: InternalUseQueryOptions<any>, configOverride?: AxiosRequestConfig) => {
  const { key, fun } = useGetEventsExtension.info(configOverride);
  return useQuery(key, fun, options);
};
useGetEventsExtension.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getEventsExtension.key] as QueryKey,
    fun: () => getEventsExtension(configOverride),
  };
};
useGetEventsExtension.prefetch = (client: QueryClient, options?: InternalUseQueryOptions<any>, configOverride?: AxiosRequestConfig) => {
  const { key, fun } = useGetEventsExtension.info(configOverride);

  return client.getQueryData(key) ? Promise.resolve() : client.prefetchQuery(key, () => fun(), options);
};

/**
 *
 * Find All Orders
 */
export const useGetOrders = (options?: InternalUseQueryOptions<any>, configOverride?: AxiosRequestConfig) => {
  const { key, fun } = useGetOrders.info(configOverride);
  return useQuery(key, fun, options);
};
useGetOrders.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getOrders.key] as QueryKey,
    fun: () => getOrders(configOverride),
  };
};
useGetOrders.prefetch = (client: QueryClient, options?: InternalUseQueryOptions<any>, configOverride?: AxiosRequestConfig) => {
  const { key, fun } = useGetOrders.info(configOverride);

  return client.getQueryData(key) ? Promise.resolve() : client.prefetchQuery(key, () => fun(), options);
};

/**
 *
 * Find All Templates
 */
export const useGetTemplates = (options?: InternalUseQueryOptions<any>, configOverride?: AxiosRequestConfig) => {
  const { key, fun } = useGetTemplates.info(configOverride);
  return useQuery(key, fun, options);
};
useGetTemplates.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getTemplates.key] as QueryKey,
    fun: () => getTemplates(configOverride),
  };
};
useGetTemplates.prefetch = (client: QueryClient, options?: InternalUseQueryOptions<any>, configOverride?: AxiosRequestConfig) => {
  const { key, fun } = useGetTemplates.info(configOverride);

  return client.getQueryData(key) ? Promise.resolve() : client.prefetchQuery(key, () => fun(), options);
};

/**
 *
 * classifiedStatistics
 */
export const useGetTemplatesClassifiedStatistics = (options?: InternalUseQueryOptions<any>, configOverride?: AxiosRequestConfig) => {
  const { key, fun } = useGetTemplatesClassifiedStatistics.info(configOverride);
  return useQuery(key, fun, options);
};
useGetTemplatesClassifiedStatistics.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getTemplatesClassifiedStatistics.key] as QueryKey,
    fun: () => getTemplatesClassifiedStatistics(configOverride),
  };
};
useGetTemplatesClassifiedStatistics.prefetch = (client: QueryClient, options?: InternalUseQueryOptions<any>, configOverride?: AxiosRequestConfig) => {
  const { key, fun } = useGetTemplatesClassifiedStatistics.info(configOverride);

  return client.getQueryData(key) ? Promise.resolve() : client.prefetchQuery(key, () => fun(), options);
};

/**
 *
 * fetch ali-oss credential
 */
export const useGetTemplatesCredential = (options?: InternalUseQueryOptions<any>, configOverride?: AxiosRequestConfig) => {
  const { key, fun } = useGetTemplatesCredential.info(configOverride);
  return useQuery(key, fun, options);
};
useGetTemplatesCredential.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getTemplatesCredential.key] as QueryKey,
    fun: () => getTemplatesCredential(configOverride),
  };
};
useGetTemplatesCredential.prefetch = (client: QueryClient, options?: InternalUseQueryOptions<any>, configOverride?: AxiosRequestConfig) => {
  const { key, fun } = useGetTemplatesCredential.info(configOverride);

  return client.getQueryData(key) ? Promise.resolve() : client.prefetchQuery(key, () => fun(), options);
};

/**
 *
 * Find All Templates from extension
 */
export const useGetTemplatesExtension = (options?: InternalUseQueryOptions<any>, configOverride?: AxiosRequestConfig) => {
  const { key, fun } = useGetTemplatesExtension.info(configOverride);
  return useQuery(key, fun, options);
};
useGetTemplatesExtension.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getTemplatesExtension.key] as QueryKey,
    fun: () => getTemplatesExtension(configOverride),
  };
};
useGetTemplatesExtension.prefetch = (client: QueryClient, options?: InternalUseQueryOptions<any>, configOverride?: AxiosRequestConfig) => {
  const { key, fun } = useGetTemplatesExtension.info(configOverride);

  return client.getQueryData(key) ? Promise.resolve() : client.prefetchQuery(key, () => fun(), options);
};

/**
 *
 * Find Template By Id
 */
export const useGetTemplatesId = (
  /**
   *
   * Template Id
   */
  id: string,
  options?: InternalUseQueryOptions<any>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetTemplatesId.info(
    id,

    configOverride,
  );
  return useQuery(key, fun, options);
};
useGetTemplatesId.info = (
  /**
   *
   * Template Id
   */
  id: string,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getTemplatesId.key, id] as QueryKey,
    fun: () =>
      getTemplatesId(
        id,

        configOverride,
      ),
  };
};
useGetTemplatesId.prefetch = (
  client: QueryClient,

  /**
   *
   * Template Id
   */
  id: string,
  options?: InternalUseQueryOptions<any>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetTemplatesId.info(
    id,

    configOverride,
  );

  return client.getQueryData(key) ? Promise.resolve() : client.prefetchQuery(key, () => fun(), options);
};

/**
 *
 * Find All teamTags from teamMembers
 */
export const useGetTemplatesTeamTags = (options?: InternalUseQueryOptions<any>, configOverride?: AxiosRequestConfig) => {
  const { key, fun } = useGetTemplatesTeamTags.info(configOverride);
  return useQuery(key, fun, options);
};
useGetTemplatesTeamTags.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getTemplatesTeamTags.key] as QueryKey,
    fun: () => getTemplatesTeamTags(configOverride),
  };
};
useGetTemplatesTeamTags.prefetch = (client: QueryClient, options?: InternalUseQueryOptions<any>, configOverride?: AxiosRequestConfig) => {
  const { key, fun } = useGetTemplatesTeamTags.info(configOverride);

  return client.getQueryData(key) ? Promise.resolve() : client.prefetchQuery(key, () => fun(), options);
};

/**
 *
 * fetch userInfo
 */
export const useGetUserInfo = (options?: InternalUseQueryOptions<UserInfo>, configOverride?: AxiosRequestConfig) => {
  const { key, fun } = useGetUserInfo.info(configOverride);
  return useQuery(key, fun, options);
};
useGetUserInfo.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getUserInfo.key] as QueryKey,
    fun: () => getUserInfo(configOverride),
  };
};
useGetUserInfo.prefetch = (client: QueryClient, options?: InternalUseQueryOptions<UserInfo>, configOverride?: AxiosRequestConfig) => {
  const { key, fun } = useGetUserInfo.info(configOverride);

  return client.getQueryData(key) ? Promise.resolve() : client.prefetchQuery(key, () => fun(), options);
};

/**
 *
 * Find All Users
 */
export const useGetUsers = (options?: InternalUseQueryOptions<any>, configOverride?: AxiosRequestConfig) => {
  const { key, fun } = useGetUsers.info(configOverride);
  return useQuery(key, fun, options);
};
useGetUsers.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getUsers.key] as QueryKey,
    fun: () => getUsers(configOverride),
  };
};
useGetUsers.prefetch = (client: QueryClient, options?: InternalUseQueryOptions<any>, configOverride?: AxiosRequestConfig) => {
  const { key, fun } = useGetUsers.info(configOverride);

  return client.getQueryData(key) ? Promise.resolve() : client.prefetchQuery(key, () => fun(), options);
};

/**
 *
 * Find User By Id
 */
export const useGetUsersId = (
  /**
   *
   * User Id
   */
  id: string,
  options?: InternalUseQueryOptions<any>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetUsersId.info(
    id,

    configOverride,
  );
  return useQuery(key, fun, options);
};
useGetUsersId.info = (
  /**
   *
   * User Id
   */
  id: string,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getUsersId.key, id] as QueryKey,
    fun: () =>
      getUsersId(
        id,

        configOverride,
      ),
  };
};
useGetUsersId.prefetch = (
  client: QueryClient,

  /**
   *
   * User Id
   */
  id: string,
  options?: InternalUseQueryOptions<any>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetUsersId.info(
    id,

    configOverride,
  );

  return client.getQueryData(key) ? Promise.resolve() : client.prefetchQuery(key, () => fun(), options);
};

/**
 *
 * get User teamMembers By manager Id
 */
export const useGetUsersTeamMembers = (options?: InternalUseQueryOptions<any>, configOverride?: AxiosRequestConfig) => {
  const { key, fun } = useGetUsersTeamMembers.info(configOverride);
  return useQuery(key, fun, options);
};
useGetUsersTeamMembers.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getUsersTeamMembers.key] as QueryKey,
    fun: () => getUsersTeamMembers(configOverride),
  };
};
useGetUsersTeamMembers.prefetch = (client: QueryClient, options?: InternalUseQueryOptions<any>, configOverride?: AxiosRequestConfig) => {
  const { key, fun } = useGetUsersTeamMembers.info(configOverride);

  return client.getQueryData(key) ? Promise.resolve() : client.prefetchQuery(key, () => fun(), options);
};

/**
 *
 * Add Events
 */
export const usePostEvents = <TExtra>(options?: InternalUseMutationOptions<any, { requestBody: RequestBodyEvents }, TExtra>) => {
  return useMutation(_o => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postEvents(
      requestBody,

      configOverride,
    );
  }, options);
};

/**
 *
 * forgot pwd step1 - send email vcode
 */
export const usePostForgotPwd = <TExtra>(options?: InternalUseMutationOptions<any, { requestBody: ForgotPwdParams }, TExtra>) => {
  return useMutation(_o => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postForgotPwd(
      requestBody,

      configOverride,
    );
  }, options);
};

/**
 *
 * login User
 */
export const usePostLogin = <TExtra>(options?: InternalUseMutationOptions<any, { requestBody: LoginUser }, TExtra>) => {
  return useMutation(_o => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postLogin(
      requestBody,

      configOverride,
    );
  }, options);
};

/**
 *
 * logout User
 */
export const usePostLogout = <TExtra>(options?: InternalUseMutationOptions<any, { requestBody: LogoutUser }, TExtra>) => {
  return useMutation(_o => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postLogout(
      requestBody,

      configOverride,
    );
  }, options);
};

/**
 *
 * Add Orders
 */
export const usePostOrders = <TExtra>(options?: InternalUseMutationOptions<any, { requestBody: Orders }, TExtra>) => {
  return useMutation(_o => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postOrders(
      requestBody,

      configOverride,
    );
  }, options);
};

/**
 *
 * validateOrder Orders
 */
export const usePostOrdersValidateOrder = <TExtra>(options?: InternalUseMutationOptions<any, { requestBody: ValidateOrderParam }, TExtra>) => {
  return useMutation(_o => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postOrdersValidateOrder(
      requestBody,

      configOverride,
    );
  }, options);
};

/**
 *
 * signup User
 */
export const usePostSignup = <TExtra>(options?: InternalUseMutationOptions<any, { requestBody: CreateUser }, TExtra>) => {
  return useMutation(_o => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postSignup(
      requestBody,

      configOverride,
    );
  }, options);
};

/**
 *
 * Add Template
 */
export const usePostTemplates = <TExtra>(options?: InternalUseMutationOptions<any, { requestBody: RequestBodyTemplates }, TExtra>) => {
  return useMutation(_o => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postTemplates(
      requestBody,

      configOverride,
    );
  }, options);
};

/**
 *
 * Find Template Content from extension
 */
export const usePostTemplatesExtensionContent = <TExtra>(options?: InternalUseMutationOptions<any, { requestBody: any }, TExtra>) => {
  return useMutation(_o => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postTemplatesExtensionContent(
      requestBody,

      configOverride,
    );
  }, options);
};

/**
 *
 * Add User
 */
export const usePostUsers = <TExtra>(options?: InternalUseMutationOptions<any, { requestBody: CreateUser }, TExtra>) => {
  return useMutation(_o => {
    const {
      requestBody,

      configOverride,
    } = _o || {};

    return postUsers(
      requestBody,

      configOverride,
    );
  }, options);
};

/**
 *
 * invite user
 */
export const usePostUsersInviteEmail = <TExtra>(
  options?: InternalUseMutationOptions<
    any,
    {
      /**
       *
       * user email
       */
      email: string;
    },
    TExtra
  >,
) => {
  return useMutation(_o => {
    const {
      email,

      configOverride,
    } = _o || {};

    return postUsersInviteEmail(
      email,

      configOverride,
    );
  }, options);
};

/**
 *
 * events done by id
 */
export const usePutEventsDoneId = <TExtra>(
  options?: InternalUseMutationOptions<
    any,
    {
      /**
       *
       * events Id
       */
      id: string;
    },
    TExtra
  >,
) => {
  return useMutation(_o => {
    const {
      id,

      configOverride,
    } = _o || {};

    return putEventsDoneId(
      id,

      configOverride,
    );
  }, options);
};

/**
 *
 * events manual By Id
 */
export const usePutEventsId = <TExtra>(
  options?: InternalUseMutationOptions<
    any,
    {
      /**
       *
       * events Id
       */
      id: string;
      requestBody: RequestBodyEvents;
    },
    TExtra
  >,
) => {
  return useMutation(_o => {
    const {
      id,
      requestBody,

      configOverride,
    } = _o || {};

    return putEventsId(
      id,
      requestBody,

      configOverride,
    );
  }, options);
};

/**
 *
 * forgot pwd step2 - udpate user pwd
 */
export const usePutForgotPwdSubmitId = <TExtra>(
  options?: InternalUseMutationOptions<
    any,
    {
      /**
       *
       * user Id
       */
      id: string;
      requestBody: ForgotPwdSubmitParams;
    },
    TExtra
  >,
) => {
  return useMutation(_o => {
    const {
      id,
      requestBody,

      configOverride,
    } = _o || {};

    return putForgotPwdSubmitId(
      id,
      requestBody,

      configOverride,
    );
  }, options);
};

/**
 *
 * orders manual By Id
 */
export const usePutOrdersEmailType = <TExtra>(
  options?: InternalUseMutationOptions<
    any,
    {
      /**
       *
       * manual type
       */
      type: string;
      /**
       *
       * manual user email
       */
      email: string;
    },
    TExtra
  >,
) => {
  return useMutation(_o => {
    const {
      type,
      email,

      configOverride,
    } = _o || {};

    return putOrdersEmailType(
      type,
      email,

      configOverride,
    );
  }, options);
};

/**
 *
 * leave team
 */
export const usePutOrdersLeave = <TExtra>(options?: InternalUseMutationOptionsVoid<any, TExtra>) => {
  return useMutation(_o => {
    const { configOverride } = _o || {};

    return putOrdersLeave(configOverride);
  }, options);
};

/**
 *
 * reset user pwd
 */
export const usePutResetPwdId = <TExtra>(
  options?: InternalUseMutationOptions<
    any,
    {
      /**
       *
       * user Id
       */
      id: string;
      requestBody: ResetPwdParams;
    },
    TExtra
  >,
) => {
  return useMutation(_o => {
    const {
      id,
      requestBody,

      configOverride,
    } = _o || {};

    return putResetPwdId(
      id,
      requestBody,

      configOverride,
    );
  }, options);
};

/**
 *
 * Template User By Id
 */
export const usePutTemplatesId = <TExtra>(
  options?: InternalUseMutationOptions<
    any,
    {
      /**
       *
       * user Id
       */
      id: string;
      requestBody: RequestBodyTemplates;
    },
    TExtra
  >,
) => {
  return useMutation(_o => {
    const {
      id,
      requestBody,

      configOverride,
    } = _o || {};

    return putTemplatesId(
      id,
      requestBody,

      configOverride,
    );
  }, options);
};

/**
 *
 * set User belongTo By Id
 */
export const usePutUsersAddmemberEmailType = <TExtra>(
  options?: InternalUseMutationOptions<
    any,
    {
      /**
       *
       * user email
       */
      email: string;
      /**
       *
       * manual type
       */
      type: string;
    },
    TExtra
  >,
) => {
  return useMutation(_o => {
    const {
      email,
      type,

      configOverride,
    } = _o || {};

    return putUsersAddmemberEmailType(
      email,
      type,

      configOverride,
    );
  }, options);
};

/**
 *
 * Update User By Id
 */
export const usePutUsersId = <TExtra>(
  options?: InternalUseMutationOptions<
    any,
    {
      /**
       *
       * user Id
       */
      id: string;
      requestBody: UpdateUser;
    },
    TExtra
  >,
) => {
  return useMutation(_o => {
    const {
      id,
      requestBody,

      configOverride,
    } = _o || {};

    return putUsersId(
      id,
      requestBody,

      configOverride,
    );
  }, options);
};

/**
 *
 * set User setting By Id
 */
export const usePutUsersSettingId = <TExtra>(
  options?: InternalUseMutationOptions<
    any,
    {
      /**
       *
       * user Id
       */
      id: string;
      requestBody: UpdateUserSetting;
    },
    TExtra
  >,
) => {
  return useMutation(_o => {
    const {
      id,
      requestBody,

      configOverride,
    } = _o || {};

    return putUsersSettingId(
      id,
      requestBody,

      configOverride,
    );
  }, options);
};
