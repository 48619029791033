import React, { createContext, useContext, useEffect } from 'react';
import { RefetchOptions, QueryObserverResult } from 'react-query';
import { useLocation } from 'react-router';
import { useLocalStorage } from '../../core/hooks/useLocalStorage';
import { usePostLogin, usePostLogout } from '../../services/hooks';
import { useUserInfo } from '../hooks/useUserInfo';
import { User } from '../types/userInfo';

interface AuthContextInterface {
  hasRole: (roles?: string[]) => {};
  isLoggingIn: boolean;
  isLoggingOut: boolean;
  login: (email: string, password: string) => Promise<any>;
  logout: () => Promise<any>;
  userInfo?: User;
  refetchUserInfo?: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<User | undefined, unknown>>;
}

export const AuthContext = createContext({} as AuthContextInterface);

type AuthProviderProps = {
  children?: React.ReactNode;
};

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [isLogin, setIsLogin] = useLocalStorage<string>('isLogin', '');

  const { isLoading: isLoggingIn, mutateAsync: login } = usePostLogin();
  const { isLoading: isLoggingOut, mutateAsync: logout } = usePostLogout();
  const { data: userInfo, refetch } = useUserInfo(isLogin);
  const location = useLocation();
  useEffect(() => {
    const validPath = ['help', 'login', 'forgot-password', 'forgot-password-submit', 'register', '403', '404'];
    const { pathname } = location;
    const isExist = validPath.some((path: string) => pathname.indexOf(path) > -1);
    if (!isExist) refetch();
  }, [location, refetch]);

  const hasRole = (roles?: string[]) => {
    if (!roles || roles.length === 0) {
      return true;
    }
    if (!userInfo) {
      return false;
    }
    return roles.includes(userInfo.role);
  };

  const handleLogin = async (email: string, password: string) => {
    return login({ requestBody: { email, password } })
      .then(async ({ data }: any) => {
        setIsLogin('yes');
        await refetch();
        return data.token;
      })
      .catch(err => {
        throw err;
      });
  };

  const handleLogout = async () => {
    return logout({ requestBody: { email: (userInfo as any)?.email } })
      .then(data => {
        setIsLogin('no');
        return data;
      })
      .catch(err => {
        throw err;
      });
  };

  return (
    <AuthContext.Provider
      value={{
        hasRole,
        isLoggingIn,
        isLoggingOut,
        login: handleLogin,
        logout: handleLogout,
        userInfo,
        refetchUserInfo: refetch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}

export default AuthProvider;
